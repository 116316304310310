import React from "react";
import { SelectInput, SelectField } from "react-admin";
import stone from "../../app/assets/adquirentes/stone.svg";
import pagbank from "../../app/assets/adquirentes/pagbank.svg";
import getnet from "../../app/assets/adquirentes/getnet.svg";
import safra from "../../app/assets/adquirentes/safrapay.png";
import rede from "../../app/assets/adquirentes/rede.png";
import sicredi from "../../app/assets/adquirentes/sicredi.png";
import caixa from "../../app/assets/adquirentes/caixa.png";
import bin from "../../app/assets/adquirentes/bin.png";

const AcquirerIcon = ({ acquirerName, ...props }) => {
  const getProps = () => {
    switch (acquirerName) {
      case "Stone":
        return {
          src: stone,
          height: "14",
        };
      case "PagBank":
        return {
          src: pagbank,
          height: "24",
        };
      case "Getnet":
        return {
          src: getnet,
          height: "16",
        };
      case "Safra":
        return {
          src: safra,
          height: "22",
        };
      case "Rede":
        return {
          src: rede,
          height: "22",
        };
      case "Sicredi":
        return {
          src: sicredi,
          height: "22",
        };
      case "Caixa":
        return {
          src: caixa,
          height: "22",
        };
      case "Bin":
        return {
          src: bin,
          height: "22",
        };
      default:
        return null;
    }
  };

  const imgProps = getProps();

  return imgProps === null ? (
    <span>{acquirerName}</span>
  ) : (
    <img {...imgProps} alt={acquirerName} {...props} />
  );
};

const adquirentes = [
  {
    id: "Stone",
    acquirerName: "Stone",
  },
  {
    id: "PagBank",
    acquirerName: "PagBank",
  },
  {
    id: "Getnet",
    acquirerName: "Getnet",
  },
  {
    id: "Safra",
    acquirerName: "Safra",
  },
  {
    id: "Rede",
    acquirerName: "Rede",
  },
  {
    id: "Sicredi",
    acquirerName: "Sicredi",
  },
  {
    id: "Caixa",
    acquirerName: "Caixa",
  },
  {
    id: "Bin",
    acquirerName: "Bin",
  },
];

const TransactionAcquireIcon = ({ record }) => {
  return <AcquirerIcon acquirerName={record.acquirerName} />;
};

function TransactionAcquireInput(props) {
  return (
    <SelectInput
      label="Adquirente"
      source="acquirerName"
      choices={adquirentes}
      optionText={<TransactionAcquireIcon />}
      {...props}
    />
  );
}

function TransactionAcquireField(props) {
  return (
    <SelectField
      label="Adquirente"
      source="acquirerName"
      choices={adquirentes}
      optionText={<TransactionAcquireIcon />}
      {...props}
    />
  );
}

export {
  TransactionAcquireInput,
  TransactionAcquireField,
  TransactionAcquireIcon,
};
