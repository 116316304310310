import React from "react";
import Card from "@material-ui/core/Card";
import DollarIcon from "@material-ui/icons/AttachMoney";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  formatNumber,
  formatCurrency,
  formatNumberNoDecimals,
} from "../common/util/formatter";

import CardIcon from "./ReportCardIcon";

const useStyles = makeStyles({
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20,
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52,
  },
});

function format(value, type) {
  switch (type) {
    case "number":
      return formatNumberNoDecimals(value);
    case "currency":
      return formatCurrency(value);
    default:
      return value;
  }
}

const ReportTotalCard = ({
  total,
  type,
  className,
  icon,
  color,
  title,
  percentage,
  component,
}) => {
  const classes = useStyles();
  const totalFormatted = format(total, type);
  return (
    <div className={[classes.main, className || ""].join(" ")}>
      <CardIcon Icon={icon} bgColor={color} />
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          {title}
        </Typography>
        <Typography variant="h5" component="h2">
          {totalFormatted}
        </Typography>
        {percentage !== undefined && (
          <Typography>{formatNumber(percentage)}%</Typography>
        )}
        {component !== undefined && component}
      </Card>
    </div>
  );
};

ReportTotalCard.defaultProps = {
  color: "#31708f",
  icon: DollarIcon,
  percentage: undefined,
  type: "text",
};

export default ReportTotalCard;
