import React from "react";
import { UserMenu, MenuItemLink } from "react-admin";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import MenuItem from "@material-ui/core/MenuItem";
import GetAppIcon from "@material-ui/icons/GetApp";
import PowerIcon from "@material-ui/icons/Power";
import PaymentIcon from "@material-ui/icons/Payment";
import { withUser } from "../common/util/hocs";
import { isHighLevel } from "../permissionsHelper";
import { iconUsuarioLog } from "../resources/usuario_log";
import { iconEquipamento } from "../resources/equipamento";
import { iconContadorArquivo } from "../resources/contador_arquivo";

function CustomUserMenu({ usuario, admin_user_active, permissao, ...rest }) {
  const showMenu = admin_user_active && isHighLevel(permissao);

  return showMenu ? (
    <UserMenu {...rest}>
      <MenuItem>{usuario.email}</MenuItem>
      <MenuItemLink
        to="/integracoes"
        primaryText="Integrações"
        leftIcon={<PowerIcon />}
      />
      <MenuItemLink
        to="/importarProdutos"
        primaryText="Importar Produtos"
        leftIcon={<GetAppIcon />}
      />
      <MenuItemLink
        to="/importarPerguntas"
        primaryText="Importar Perguntas"
        leftIcon={<GetAppIcon />}
      />
      <MenuItemLink
        to="/importarAreasEntrega"
        primaryText="Importar Áreas de entrega"
        leftIcon={<GetAppIcon />}
      />
      <MenuItemLink
        to="/equipamentos"
        primaryText="Equipamentos"
        leftIcon={iconEquipamento}
      />
      <MenuItemLink
        to="/Transaction_pos"
        primaryText="Transações"
        leftIcon={<PaymentIcon />}
      />
      <MenuItemLink
        to="/Contador_arquivo"
        primaryText="Contador arquivos"
        leftIcon={iconContadorArquivo}
      />
      <MenuItemLink
        to="/user_logs"
        primaryText="Logs"
        leftIcon={iconUsuarioLog}
      />
      <MenuItemLink
        to="/alterarSenha"
        primaryText="Alterar senha"
        leftIcon={<VpnKeyIcon />}
      />
    </UserMenu>
  ) : (
    <UserMenu {...rest}>
      <MenuItem>{usuario.email}</MenuItem>
    </UserMenu>
  );
}

export default withUser(CustomUserMenu);
