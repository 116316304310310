import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Chip,
} from "@mui/material";
import { getList } from "../../dataProvider";

function Equipamentos({ value, onChange, id }) {
  const [equipamentos, setEquipamentos] = useState([]);

  const handleChange = (event) => onChange(id, event.target.value);

  useEffect(() => {
    getList("equipamentos", {
      filter: { ativo: true },
      order: "ultimo_uso DESC",
    }).then((r) => setEquipamentos(r.data));
  }, []);

  return (
    <FormControl sx={{ minWidth: 120 }}>
      <InputLabel>Equipamentos</InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <div>
            {selected.map((value) => (
              <Chip
                key={value}
                label={equipamentos.find((e) => e.id === value)?.nome}
              />
            ))}
          </div>
        )}
      >
        {equipamentos.map((e) => (
          <MenuItem key={e.id} value={e.id}>
            {e.nome}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default Equipamentos;
