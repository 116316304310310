import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Title, useAuthenticated } from "react-admin";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Stack,
  Grid,
  Typography,
} from "@mui/material";
import Decimal from "decimal.js";
import moment from "moment";
import { Line } from "react-chartjs-2";
import { fetchJSON } from "../dataProvider";
import UXDelay from "../dataProvider/uxdelay";
import { useStyles } from "./ReportDailyInvoicings/styles";
import isEmpty from "lodash.isempty";
import Search from "./Search";
import { formatCurrency } from "../common/util/formatter";
import { withUser } from "../common/util/hocs";
import ReportTotalCard from "./ReportTotalCard";
import { reportPDVEnable } from "../permissionsHelper";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import WarningIcon from "@material-ui/icons/Warning";
import CancelIcon from "@material-ui/icons/Cancel";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

const ReportNFCe = ({ usuario, loja, ...props }) => {
  useAuthenticated();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  async function retrieveReportData({ startDate, endDate, equipamentos }) {
    setLoading(true);
    try {
      const newData = await UXDelay(
        fetchJSON("Relatorio/pdv/nfce_totais", null, {
          lojaId: loja.id,
          startDate,
          endDate,
          equipamentos,
        })
      );

      setData(newData);
    } catch (err) {
      console.log(JSON.stringify(err));
    } finally {
      setLoading(false);
    }
  }

  const lineChartOptions = {
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        display: false,
      },
    },
    elements: {
      line: {
        fill: false,
        tension: 0.4,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
      },
    },
  };

  const dataEmpty = isEmpty(data) || Decimal(data?.total ?? 0).isZero();

  return (
    <Card className={classes.root}>
      <Title title="NFCe Totais" />
      <CardContent>
        <Search
          fields={["dateRange", "timeRange", "equipamentos"]}
          onSearch={retrieveReportData}
          loading={loading}
          dataEmpty={dataEmpty}
        />
        {!dataEmpty && (
          <div>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
              sx={{ my: 5 }}
            >
              <Grid item xs={3}>
                <ReportTotalCard
                  total={data.total}
                  icon={AttachMoneyIcon}
                  type="currency"
                  title={"Total"}
                  color={"#36A2EB"}
                />
              </Grid>
              <Grid item xs={3}>
                <ReportTotalCard
                  total={data.totalAutorizada}
                  icon={VerifiedUserIcon}
                  title="Total Autorizadas"
                  type="currency"
                  color={"green"}
                />
              </Grid>
              <Grid item xs={3}>
                <ReportTotalCard
                  total={data.totalPendente}
                  icon={WarningIcon}
                  type="currency"
                  title={"Total Pendentes"}
                  color="rgb(255, 193, 7)"
                  component={
                    <Link
                      to={`/NFCes?displayedFilters=%7B"nfce_pendente"%3Atrue%7D&filter=%7B"nfce_pendente"%3Atrue%7D&order=DESC&page=1&perPage=10&sort=id`}
                      rel="noopener noreferrer"
                    >
                      <Typography sx={{ m: 0 }}>{`ver pendentes`}</Typography>
                    </Link>
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <ReportTotalCard
                  total={data.totalCancelada}
                  icon={CancelIcon}
                  type="currency"
                  title={"Total Cancelada"}
                  color={"red"}
                  component={
                    <Link
                      to={`/NFCes?displayedFilters=%7B"cancelada"%3Atrue%7D&filter=%7B"cancelada"%3Atrue%7D&order=DESC&page=1&perPage=10&sort=id`}
                      rel="noopener noreferrer"
                    >
                      <Typography sx={{ m: 0 }}>{`ver canceladas`}</Typography>
                    </Link>
                  }
                />
              </Grid>
            </Grid>

            <Stack spacing={5}>
              <Grid container>
                <Grid item xs={12}>
                  <Card className={classes.card}>
                    <CardHeader
                      className={classes.cardHeader}
                      title={"Total emitido por dia"}
                    />
                    <CardContent>
                      <Box height={500}>
                        <Line
                          data={getLineChartData(data)}
                          options={lineChartOptions}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Stack>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

function getLineChartData(data) {
  const labels = data.totalPorDiaStatus.map((i) =>
    moment(i.data.substring(0, 10)).format("DD/MM/YYYY")
  );
  const values = data.totalPorDiaStatus.map((i) => i.total);
  return {
    labels: labels,
    datasets: [
      {
        label: "Total",
        data: values,
        fill: false,
        borderColor: "#36A2EB",
        backgroundColor: "#36A2EB",
        tension: 0.3,
        datalabels: {
          display: "auto",
          align: "top",
          anchor: "top",
          formatter: (value, _) => formatCurrency(value),
          color: "black",
          borderRadius: 4,
          padding: 6,
          font: {
            weight: "bold",
          },
        },
      },
    ],
  };
}

export default withUser(ReportNFCe);

export const reportNFCe = {
  getMenu: (p, a) =>
    reportPDVEnable(p, a)
      ? {
          key: "relatorioNFCe",
          to: "/relatorio/nfce",
          primaryText: "NFCe",
        }
      : null,
};

//
//  EXEMPLO DOS DADOS
//
// {
//   "totalPorDiaStatus": [
//     {
//       "data": "2024-10-15T00:00:00.000Z",
//       "total": "53.5",
//       "Autorizada": 53.5
//     },
//     {
//       "data": "2024-10-16T00:00:00.000Z",
//       "total": "30.88",
//       "Autorizada": 30.88
//     },
//     {
//       "data": "2024-10-17T00:00:00.000Z",
//       "total": "34",
//       "Autorizada": 34
//     }
//   ],
//   "totalCancelada": 0,
//   "totalPendente": 0,
//   "totalAutorizada": 774.57,
//   "total": 774.57
// }
