import React, { useState } from "react";
import LoopIcon from "@material-ui/icons/Loop";
import LoadingButton from "../../../common/LoadingButton";
import { fetchJSON } from "../../../dataProvider";
import { useNotify } from "react-admin";
import { withUser } from "../../../common/util/hocs";

const NFeStatusButton = ({ loja }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const statusSefaz = async () => {
    try {
      setLoading(true);
      const status = await fetchJSON("sefaz/consultaStatusNFe", {
        method: "POST",
        body: {
          lojaId: loja.id,
        },
      });
      notify(status);
    } catch (err) {
      console.log(JSON.stringify(err));
      notify("Ocorreu um erro ao consultar o status da SEFAZ.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      variant="contained"
      text="Status NFe"
      loading={loading}
      icon={<LoopIcon />}
      onClick={statusSefaz}
    />
  );
};

export default withUser(NFeStatusButton);
