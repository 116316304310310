import React from "react";
import { Title, useAuthenticated, useRedirect } from "react-admin";
import { Grid, Box, Card, CardActions, CardContent } from "@mui/material";
import { withUser } from "../common/util/hocs";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IFoodLogo } from "./IFood";
import { MercadoPagoLogo } from "./MercadoPago";
import { CertificadoDigitalLogo } from "./CertificadoDigital";
import { ComprovanteLogo } from "./ImpressaoConfig";
import { MatrizFilialLogo } from "./MatrizFilial";
import { PagarmeLogo } from "./Pagarme";
import { AutoatendimentoLogo } from "./AutoatendimentoConfig";
import { WhatsAppLogo } from "./WhatsApp";
import { EtiquetaLogo } from "./EtiquetaConfig";

function MediaCard({ mediaComponent, title, text, uri }) {
  const redirect = useRedirect();

  const handleClick = () => {
    redirect(`/integracoes/${uri}`);
  };

  return (
    <Card sx={{ width: 300 }}>
      <Box sx={{ height: 260 }}>{mediaComponent}</Box>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {text}
        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={handleClick}>Detalhes</Button>
      </CardActions>
    </Card>
  );
}

function Integracoes(props) {
  useAuthenticated();

  return (
    <Grid container spacing={6} sx={{ p: 3 }} justifyContent="space-around">
      <Title title="Integrações" />
      <Grid item>
        <MediaCard
          mediaComponent={<WhatsAppLogo />}
          title="🤖 Chatbot"
          text="🤖 Chatbot para responder seus clientes no WhatsApp"
          uri="whatsapp-config"
        />
      </Grid>
      <Grid item>
        <MediaCard
          mediaComponent={<MercadoPagoLogo />}
          title="Mercado Pago"
          text="Integra os meios de pagamento do mercado pago no Sistema Pallas"
          uri="mercadoPago"
        />
      </Grid>
      <Grid item>
        <MediaCard
          mediaComponent={<IFoodLogo />}
          title="iFood"
          text="Integra os os pedidos do iFood no Sistema Pallas"
          uri="ifood"
        />
      </Grid>
      <Grid item>
        <MediaCard
          mediaComponent={<CertificadoDigitalLogo />}
          title="Certificado Digital"
          text="Realiza o monitoramento de notas emitidas contra seu CNPJ"
          uri="certificado-digital-config"
        />
      </Grid>
      <Grid item>
        <MediaCard
          mediaComponent={<ComprovanteLogo />}
          title="Impressão"
          text="Configura as opções de impressão da conferência e produção"
          uri="impressao-config"
        />
      </Grid>
      <Grid item>
        <MediaCard
          mediaComponent={<MatrizFilialLogo />}
          title="Matriz Filial"
          text="Configura a operação de matriz filial"
          uri="matriz-filial-config"
        />
      </Grid>
      <Grid item>
        <MediaCard
          mediaComponent={<PagarmeLogo />}
          title="Stone / Pagarme"
          text="Integração com Stone / Pagar.me"
          uri="stone-pagarme-config"
        />
      </Grid>
      {/* <Grid item>
        <MediaCard
          mediaComponent={<PagBankLogo />}
          title="PagBank"
          text="Integração com o PagBank"
          uri="pagbank-config"
        />
      </Grid> */}
      <Grid item>
        <MediaCard
          mediaComponent={<AutoatendimentoLogo />}
          title="Autoatendimento"
          text="Configura o Autoatendimento"
          uri="autoatendimento-config"
        />
      </Grid>
      <Grid item>
        <MediaCard
          mediaComponent={<EtiquetaLogo />}
          title="Etiqueta"
          text="Configure a etiqueta geradas pelas balanças"
          uri="etiqueta-config"
        />
      </Grid>
      {/* <Grid item>
        <MediaCard
          mediaComponent={<PickNGoLogo />}
          title="PickNGo"
          text="Permite chamar os entregadores da PickNGo através do Sistema Pallas"
          uri="pickNGo"
        />
      </Grid> */}
      {/* <Grid item>
        <MediaCard
          mediaComponent={<BeeDeliveryLogo />}
          title="BeeDelivery"
          text="Permite chamar os entregadores da BeeDelivery através do Sistema Pallas"
          uri="beeDelivery"
        />
      </Grid> */}
    </Grid>
  );
}

export default withUser(Integracoes);
