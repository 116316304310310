import React from "react";
import { Edit } from "react-admin";

import ComandaForm from "./ComandaForm";

const ComandaEdit = (props) => {
  return (
    <Edit {...props}>
      <ComandaForm />
    </Edit>
  );
};

export default ComandaEdit;
