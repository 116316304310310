import React from "react";
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  maxLength,
  Labeled,
} from "react-admin";
import { IdDates } from "../../common/form/ConditionalInputs";
import { withUser } from "../../common/util/hocs";
import { QRCodeCanvas } from "qrcode.react";
import { Box } from "@mui/material";

const validateCodigo = [required(), maxLength(10)];

function ComandasForm(props) {
  const { record, loja, toolbar } = props;
  const urlMesa =
    record && record.id
      ? `https://autoatendimento.sistemapallas.com.br/${loja.appDeliveryUri}/autoatendimento/mesa-${record.codigo}-${record.id}`
      : "";
  const appUrl = encodeURI(urlMesa);

  return (
    <SimpleForm
      {...props}
      toolbar={record.movimentoId > 0 ? null : toolbar}
      redirect="list"
    >
      <IdDates />
      <TextInput source="codigo" label="Código" validate={validateCodigo} />
      <BooleanInput source="prepaga" />
      <BooleanInput source="bloqueada" />

      {record.id && loja.appMesa && (
        <Box display="grid" sx={{ mb: 5 }} fullWidth>
          <Labeled label="Link de pedidos para o tablet">
            <a href={appUrl} target="_blank" rel="noopener noreferrer">
              {appUrl}
            </a>
          </Labeled>
          <QRCodeCanvas value={appUrl} />
        </Box>
      )}
      {record.movimentoId > 0 && (
        <>
          <Labeled label="Movimento id">
            <span>{record.movimentoId}</span>
          </Labeled>
          <p>Comanda em uso, não é possível editar.</p>
        </>
      )}
    </SimpleForm>
  );
}

export default withUser(ComandasForm);
